<template>
  <div>
    <el-row>
      <el-col :span="24">
        <img :src="bg" alt="123" class="resize" style="margin-bottom: -7px;">
        <div class="navbar">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/home/support' }">支持</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/home/support' }">常见问题</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" style="padding: 0 25%;">
        <h1 class="title">常见问题</h1>
        <div class="info">
          <div class="menu">
            <el-input v-model="input" placeholder="搜索"></el-input>
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item title="AIO-5" name="1">
                <div class="menu_item">
                  <el-link href="/#/home/ofenanswer/ofen_one" :underline="false">OBD常见问题</el-link>
                </div>
                <div class="menu_item">
                  <el-link href="/#/home/ofenanswer/ofen_two" :underline="false">qq音乐问题</el-link>
                </div>
                <div class="menu_item">
                  <el-link href="/#/home/ofenanswer/ofen_three" :underline="false">导航常见问题</el-link>
                </div>
                <div class="menu_item">
                  <el-link href="/#/home/ofenanswer/ofen_four" :underline="false">手机投屏问题</el-link>
                </div>
                <div class="menu_item">
                  <el-link href="/#/home/ofenanswer/ofen_five" :underline="false">胎压常见问题</el-link>
                </div>
                <div class="menu_item">
                  <el-link href="/#/home/ofenanswer/ofen_six" :underline="false">行车记录问题</el-link>
                </div>
                <div class="menu_item">
                  <el-link href="/#/home/ofenanswer/ofen_seven" :underline="false">音频及手机互联问题</el-link>
                </div>
                <div class="menu_item">
                  <el-link href="/#/home/ofenanswer/ofen_eight" :underline="false">其它问题</el-link>
                </div>
              </el-collapse-item>
              <el-collapse-item title="CG2" name="2">
                <div>控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；</div>
                <div>页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。</div>
              </el-collapse-item>
              <el-collapse-item title="XR-3" name="3">
                <div>简化流程：设计简洁直观的操作流程；</div>
                <div>清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；</div>
                <div>帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。</div>
              </el-collapse-item>
              <el-collapse-item title="G3" name="4">
                <div>用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；</div>
                <div>结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。</div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="content">
            <router-view />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bg: require('../../../public/images/aio/G3.png'),
      activeName: '1', // 手风琴
      input: '' // 搜索输入框
    }
  }
}
</script>
<style lang="less" scoped>
.el-col {
  background-color: #f8f8f8;
}
.navbar {
  height: 40px;
  padding: 15px 25% 0;
  background: #dfdfdf;
}
.resize {
  width: 100%;
  height: 100%;
}
.title {
  margin-top: 80px;
  height: 60px;
  line-height: 60px;
  text-align: left;
  border-bottom: 1px solid #dfdfdf;
  text-indent: 1em;
}
.info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  .menu {
    flex: 1;
    // height: 300px;
    border-right: 1px solid #dfdfdf;
    ::v-deep .el-input__inner {
      width: 95%;
      background-color: #ebebeb;
      border: none;
      margin-top: 40px;
    }
    ::v-deep .el-collapse-item__header {
      font-size: 16px;
      background-color: #f8f8f8;
      border-bottom: 0;
    }
    ::v-deep .el-collapse-item__wrap {
      background-color: #f8f8f8;
      border-bottom: 0;
    }
    ::v-deep .el-collapse-item__content {
      padding: 0;
    }
    .menu_item {
      height: 40px;
      line-height: 40px;
      text-indent: 1em;
    }
    .menu_item:hover {
      background-color: #ebebeb;
      border-left: 2px solid #0088fe;
    }
  }
  .content {
    flex: 3;
  }
}
</style>